<template>
  <div>
    <router-view />
  </div>
</template>

<script>
/*
 * This is the main page for the /site/search route.
 *
 * Here, we will initially load all the common states needed for route searches.
 *
 * - Campaigns
 * - Offerings
 *
 */

export default {
  name: 'SearchIndex',
}
</script>

<style scoped lang="scss"></style>
